
<template>
  <div class="Serve_I">
    <div class="headNavs">
      <div class="headTilte">服务顾问</div>
      <div class="headDetails">可进行线上与线下营销的员工可以申请未服务顾问，本次服务商具备多站点同时接单、资讯的能力</div>
    </div>

    <!-- 搜索导航 -->
    <div class="searchNav">
      <div class="NavLi">
          <div class="NavLiName">站点：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.site_id" placeholder="请选择站点">
              <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiName">状态：</div>
          <div class="NavLiIpt">
            <el-select v-model="search.status" placeholder="全部">
              <el-option v-for="(item, i) in statisticsColumns.status" :key="i" :label="item" :value="i"></el-option>
            </el-select>
          </div>
      </div>
      <div class="NavLi" style="flex:1.5;">
          <div class="NavLiName" style="margin-left:15px">创建时间：</div>
          <div class="NavLiIpt">
            <el-date-picker v-model="search.time.min" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
            <span class="timeSpan">至</span>
            <el-date-picker v-model="search.time.max" type="date" placeholder="选择月份" :clearable="false"></el-date-picker>
          </div>
      </div>
      <div class="NavLi">
          <div class="NavLiQuery" @click="BtnSearch()">查询</div>
          <div class="NavLiReset" @click="BtnSearch(false)">重置</div>
      </div>
    </div>

    <!-- 列表 -->
    <div class="TablesList">
      <div class="TablesADD">
        <div class="NavLiQuery" @click="showAdd()"><i class="el-icon-plus"></i>创建</div>
        <div class="NavLiDelte" @click="Add_Del()"><i class="el-icon-delete"></i>批量删除</div>
        <div class="TableIpt"> 
          <input @keyup.enter.native="BtnSearch()" type="text" v-model="search.keyword" placeholder="请输入内容">
          <div class="seso" @click="BtnSearch()"><i class="el-icon-search"></i></div>
        </div>
      </div>
      <el-table
        :data="tableData"
        class="table"
        ref="multipleTable"
        header-cell-class-name="table-header"
        @selection-change="handhange">
        <el-table-column type="selection"  width="55" align="center"></el-table-column>
        <el-table-column  prop="id"  label="ID" width="55" align="center"></el-table-column>
        <el-table-column label="站点" align="center">
          <template #default="scope" >
             <div v-for="item in scope.row.site" :key="item.index">{{item}}&nbsp;&nbsp;</div>
          </template>
        </el-table-column>
        <el-table-column align="center" prop="username" label="服务顾问"></el-table-column>
        <el-table-column align="center" label="头像">
           <template #default="scope" >
             <img class="Toper" :src="scope.row.full_avatar" alt="">
           </template>
        </el-table-column>
        <el-table-column align="center" prop="phone" label="手机"></el-table-column>
        <el-table-column align="center" prop="order_num" label="接单数"></el-table-column>
        <el-table-column align="center" prop="site.name" label="服务商品">
           <template #default="scope" >
             <el-button type="text" @click="Chkkk(scope.row)" >查看</el-button>
           </template>              
        </el-table-column>
        <el-table-column align="center" prop="status_text" label="状态"></el-table-column>
        <el-table-column align="center" prop="createtime" label="创建时间"></el-table-column>
        <el-table-column label="操作" align="center" width="200">
          <template #default="scope">
              <div class="el_butto" >
                <el-button
                type="text"
                @click="HandleEdit(scope.row)"
                >编辑</el-button>
              </div>
            <div class="el_butto rere" >
                <el-button
                type="text"
                class="red"
                @click="HandleDel(scope.row)"
                >删除</el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <div class="block">
        <el-pagination
          background
          @current-change="handleCurrentChange"
          :current-page="page.currentPage"
          :page-size="page.pageSize"
          layout="total, prev, pager, next, jumper"
          :total="total">
        </el-pagination>
      </div>
    </div>

    <!-- 编辑新增 -->
    <div class="show" v-if="ruleForm2">
      <div class="rightPanel-background"/>
      <div class="EditNew">
        <div class="EditNewTop">
          <div>{{titleMath}}</div>
          <div @click="Close()"><i class="el-icon-close"></i></div>
        </div>
        <el-form class="EditNewForm" :model="Row" :rules="rules" ref="Row">
          <el-form-item class="EditNewFormItem" prop="username">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>服务商姓名</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.username" placeholder="请填写服务商姓名"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="year">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>工作年限</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.year" placeholder="请输入工作年限"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="phone">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>电话号码</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.phone" placeholder="请填写手机号码"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="qq">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>QQ号码</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.qq" placeholder="请填写QQ号码"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="long_lat">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>经纬度</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.long_lat" placeholder="请选择坐标"  @focus="latitude()"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="address">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>联系地址</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.address" placeholder="请填写联系地址"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="company">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>公司名称</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.company" placeholder="请填写公司名称"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="business_scope">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>经营范围</div>
            <div class="EditNewFormItemIpt">
              <el-input v-model="Row.business_scope" placeholder="请填写经营范围"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="order_num">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>接单数</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.order_num" placeholder="请填写接单数"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="weigh">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>权重</div>
            <div class="EditNewFormItemIpt">
              <el-input type="number" v-model="Row.weigh" placeholder="请填写权重"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="company_intro">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>公司简介</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="Row.company_intro" placeholder="请填写公司简介"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="intro">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>个人简介</div>
            <div class="EditNewFormItemIpt">
              <el-input type="textarea" v-model="Row.intro" placeholder="请填写个人简介"></el-input>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns" prop="cate_ids">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>服务类别</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.cate_ids" placeholder="请选择服务类别" multiple >
                <el-option v-for="item in statisticsColumns.category" :key="item.name" :value="item.id" :label="item.name"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="site_ids">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>站点</div>
            <div class="EditNewFormItemIpt">
               <el-select v-model="Row.site_ids" placeholder="请选择站点" multiple>
                  <el-option v-for="item in SiteList" :key="item.site_id" :label="item.name" :value="item.site_id"></el-option>
                </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="default">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>默认状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.default" placeholder="请选择默认状态">
                <el-option v-for="(item, i) in statisticsColumns.default" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="staff_id">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>选择员工</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.staff_id" placeholder="请选择员工">
                <el-option v-for="item in StaFFList" :key="item.id" :label="item.realname" :value="item.id"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="tuijian">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>推荐状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.tuijian" placeholder="请选择推荐状态">
                <el-option v-for="(item, i) in statisticsColumns.tuijian" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormItem" prop="status">
            <div class="EditNewFormItemName"><span class="signSpan">* </span>启用状态</div>
            <div class="EditNewFormItemIpt">
              <el-select v-model="Row.status" placeholder="请选择是否显示">
                <el-option v-for="(item, i) in statisticsColumns.status" :key="i" :label="item" :value="i"></el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">头像</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <img v-if="Row.full_avatar" :src="Row.full_avatar" class="Avatar" />
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :limit="1"
                  :on-success="UP_Success"
                  :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="EditNewFormItemName">微信二维码</div>
            <div class="EditNewFormItemIpt">
              <div class="Picture">
                <img v-if="Row.full_wxqrcode" :src="Row.full_wxqrcode" class="Avatar" />
                <el-upload
                  :action="upload"
                  :headers="heads"
                  list-type="picture-card"
                  :limit="1"
                  :on-success="UP_Success"
                  :on-preview="handlePictureCardPreview">
                  <i class="el-icon-plus"></i>
                  <div class="elsff">上传图片</div>
                </el-upload>
              </div>
            </div>
          </el-form-item>
          <el-form-item class="EditNewFormBtns">
            <div class="FormBtns">
              <div v-if="resetForm" class="FormBtnsQuery" @click="BtnAdd()">创建</div>
              <div v-if="!resetForm" class="FormBtnsQuery" @click="BtnEdit()">保存</div>
              <div class="FormBtnsClose" @click="Close()">取消</div>
            </div>
          </el-form-item>
        </el-form>
      </div>
    </div>

    <!-- 添加位置 -->
    <el-dialog :title="titleMath" v-model="editVisible" width="35%">
      <el-form ref="form" :model="form" label-width="100px">
        <el-form-item label="详细地址">
          <el-input v-model="Location.address"></el-input>
        </el-form-item>
        <div id="container"></div>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="positionAdd()">确 定</el-button>
        </span>
      </template>
    </el-dialog>

    <!--查看服务商品-->
    <el-dialog title="服务商品" v-model="show_Fw" width="36%">
      <div class="Service"  v-for="item in NNN_2" :key="item.value" :label="item.content" :value="item.value">
        <div class="ServiceName">{{item.name}}</div>
        <el-checkbox class="checkbox" v-for="item2 in item.goodsList" :key="item2.value" v-model="item2.checked" @change="Chan_box(item2)">{{item2.goods_name}}</el-checkbox>
      </div>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @change="Chan_box(item2)" @click="Sure_check()">确 认</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import AMapLoader from '@amap/amap-jsapi-loader'
	window._AMapSecurityConfig = {
  		securityJsCode: '0e421469d1396f611c38c84171bede50'
	}
export default {
  name: "basetable",
  data() {
    return{
      upload:'https://rest-api.duxin365.com/admin/ajax/upload',
      heads:{token:''},
      tableData:[],//总列表.
      SiteList:[],      //站点列表
      statisticsColumns:[],
      Row_Eurl:[],          //图片
      Row_Eurl2:[],          //图片
      StaFFList:[],
      multipleSelection:[],
      BRR:[],
      titleMath:'',
      total: '',
      editId:'',
      NNN_2:'',
      Chek_id:'',
      multipleSelection_id:'',
      ruleForm2: false,
      resetForm: false,
      show_Fw:false,
      editVisible:false,
      page: {  // 分页
        currentPage: 1,
        pageSize: 10,
      },
      search: {  //搜索参数
        keyword: "",
        site_id:'',
        status:'',
        time:{
          min:'',
          max:'',
        },
      }, 
      Location:{
        name:'',
        address:'',
        latitude:'',
        longitude:'',
      },
      rules:{
        username: [{ required: true, message: '请输入', trigger: 'blur' }],
        year: [{ required: true, message: '请输入', trigger: 'blur' }],
        phone: [{ required: true, message: '请输入', trigger: 'blur' }],
        qq: [{ required: true, message: '请输入', trigger: 'blur' }],
        long_lat: [{ required: true, message: '请输入', trigger: 'blur' }],
        address: [{ required: true, message: '请输入', trigger: 'blur' }],
        company: [{ required: true, message: '请输入', trigger: 'blur' }],
        business_scope: [{ required: true, message: '请输入', trigger: 'blur' }],
        order_num: [{ required: true, message: '请输入', trigger: 'blur' }],
        weigh: [{ required: true, message: '请输入', trigger: 'blur' }],
        company_intro: [{ required: true, message: '请输入', trigger: 'blur' }],
        intro: [{ required: true, message: '请输入', trigger: 'blur' }],
        cate_ids: [{ required: true, message: '请输入', trigger: 'change' }],
        site_ids: [{ required: true, message: '请输入', trigger: 'change' }],
        default: [{ required: true, message: '请输入', trigger: 'change' }],
        staff_id: [{ required: true, message: '请输入', trigger: 'change' }],
        tuijian: [{ required: true, message: '请输入', trigger: 'change' }],
        company_intro: [{ required: true, message: '请输入', trigger: 'change' }],
        status: [{ required: true, message: '请输入', trigger: 'change' }],
      } , 
      Row:{
        staff_id:'',
        username:'',
        phone:'',
        qq:'',
        intro:'',
        address:'',
        long_lat:'',
        year:'',
        company:'',
        company_intro:'',
        business_scope:'',
        weigh:'',
        order_num:'',
        default:'',
        tuijian:'',
        status:'',
        avatar:'',
        wxqrcode:'',
        site_ids:[],
        cate_ids:[],
      }
    }
  },
  mounted(){
    var that = this
    let token = window.localStorage.getItem("accessToken")
    that.heads.token = token
    that.loadTable()
    that.liebiao()
  },
  methods: {
    // 获取列表数据
    loadTable() {
      var that = this
      axios({
        method: 'post',
        url: '/admin/company/business/businessList',
        data: {
         page: that.page.currentPage,
         limit: that.page.pageSize,
         filter: that.search,
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    }, 
    showAdd(){
      var that = this
      that.ruleForm2 = true
      that.titleMath = '添加员工'
      that.resetForm = true
      that.liebiao()
    },
    BtnAdd(){
      var that = this
      console.log(1121);
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'post',
            url: '/admin/company/business/addBusiness',
            data: {
              row: that.Row
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleForm2 = false
              that.loadTable();
            }
          })
        }
      })
    },
    // 获取编辑内容
    HandleEdit(data) {                        
      var that = this
      that.ruleForm2 = true;
      that.resetForm = false
      that.titleMath = '编辑员工'
      that.editId = data.id
      that.liebiao()
      axios.get('/admin/company/business/businessInfo', {  
        params: {
          id: data.id
        }
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');
        } else {
          that.Row = res.data.data.row
        }
      })
    },
    BtnEdit(){
      var that = this
      that.$refs['Row'].validate((valid) =>{
        if (valid) {
          axios({
            method: 'PUT',
            url: '/admin/company/business/editBusiness',
            data: {
              id: that.editId,
              row: that.Row
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.ruleForm2 = false
              that.loadTable();
            }
          })
        }
      })
    },
    Add_Del() {             //批量操作
      var that = this
      if (that.multipleSelection_id == '') {      //批量删除
        that.$message.warning('请勾选信息');
      } else {
        that.$confirm("确定要删除吗？", "提示", {
          type: "primary"
        }).then(() => {
          axios({
            method: 'delete',
            url: '/admin/company/business/delBusiness',
            data: {
              ids: that.multipleSelection_id
            },
          }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else {
              that.$message.success(res.data.msg);
              that.loadTable()
            }
          }) 
        })
      }
    },
    //删除弹出框
    HandleDel(data) {
      var that = this
      var arr = []
      arr.push(data.id)
      that.$confirm("确定要删除吗？", "提示", {
        type: "primary"
      }).then(() => {
        axios({
          method: 'DELETE',
          url: '/admin/company/business/delBusiness',
          data: {
            ids : arr
          }
        }).then(function (res) {
          if (res.data.code == 0) {
            that.$message.warning(res.data.msg);
          } else {
            that.$message.success(res.data.msg);
            that.loadTable();
          }
        })
      })
    },
    //搜索
    BtnSearch(e) {
      var that = this
      that.page.currentPage = 1;
      if (e == false) {
        that.search = {
          keyword: "",
          site_id:'',
          status:'',
          time:{
            min:'',
            max:'',
          },
        };
      }
      axios({
        method: 'post',
        url: '/admin/company/business/businessList',
        data: {
          page: that.page.currentPage,
          limit: that.page.pageSize,
          filter: that.search,
          filter:{
            keyword:  that.search.keyword,
            site_id: that.search.site_id,
            status: that.search.status,
            time:{
              min: that.search.time.min,
              max: that.search.time.max,
            },
          }
        },
      }).then(function (res) {
        that.tableData = res.data.data.rows
        that.total = res.data.data.total
        that.$forceUpdate()
      })
    },
    handleCurrentChange(page) {  //分页
      var that = this
      that.page.currentPage = page;
      that.loadTable();
    },
    latitude(){  //经纬度打开
        var that = this
        that.titleMath = '添加位置'
        that.editVisible = true
        that.initMap();
    },
    initMap() {
      AMapLoader.load({
        key: "3d62b06249da3d2faabfb630eb3916b0", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      }).then((AMap) => {
          window.AMap = AMap;
          this.map = new AMap.Map("container", {
            //设置地图容器id
            viewMode: "3D", //是否为3D地图模式
            zoom: 11, //初始化地图级别
            center: [118.771409, 32.067939], //初始化地图中心点位置
          });// 设置点样式
          this.icon = new AMap.Icon({
              image: '//a.amap.com/jsapi_demos/static/demo-center/icons/poi-marker-default.png',  // Icon的图像
              offset: new AMap.Pixel(-13, -30)
          });
          let geocoder
          AMap.plugin('AMap.Geocoder',function(){
            geocoder = new AMap.Geocoder()
          })

          this.geocoder = geocoder
          var that= this
          that.map.on('click', function (e) {
            var lnglatXY = [e.lnglat.getLng(), e.lnglat.getLat()];
            that.openPop(lnglatXY)
          });
        }).catch((e) => {
          console.log(e);
        });
    },
    openPop(e) {
        var that = this
        console.log('asd')
        that.markersPosition = e
        that.Location.latitude = e[0]
        that.Location.longitude = e[1]
        // 设置新的标记
        console.log(e)
        AMap.plugin('AMap.Geocoder', function () {
          var geocoder = new AMap.Geocoder({
            radius: 1000,
            extensions: "all"
          });
          console.log(geocoder)
          geocoder.getAddress(e, function (status, result) {
            if (status === 'complete' && result.info === 'OK') {
              var address = result.regeocode.formattedAddress;
              that.Location.name = result.regeocode.pois[0].name  
              that.Location.address = result.regeocode.formattedAddress
            } else {
              console.log(status)
            }
          });
          // 清除所有标记
          that.map.clearMap();
          var marker = new AMap.Marker();
          that.map.add(marker);
          marker.setPosition(e);
        })
    },
    positionAdd(){      //弹框确认
      var that = this
      var arr = that.Location
      that.editVisible = false
      that.Row.long_lat = arr.latitude + ',' + arr.longitude
      that.Row.address = arr.address
      setTimeout(function(){
        that.editVisible = false;
      },1) 
    },
    Chkkk(e) {                          //查看
      console.log(e)
      var that = this
      that.Chek_id = e.id
      that.show_Fw = true
      axios.get('/admin/company/business/goodsList',{
        params:{
                id: e.id
              }
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败1');  
          } else{
            that.NNN_2 = res.data.data.categoryList
            that.BRR = res.data.data.goodsIds
            }
      })
    },
    Chan_box(e) {
      var that = this
      console.log(e)
      
      var Arr = that.NNN_2
      var VRR = that.BRR

      for (let i = 0; i < Arr.length; i++) {
        const el_1 = Arr[i];
        for (let o = 0; o < el_1.goodsList.length; o++) {
          const el_2 = el_1.goodsList[o];
          if(e.goods_id == el_2.goods_id) {
            if (e.checked == true) {
              VRR.push(el_2.goods_id)
            } else {
              for (let u = 0; u < VRR.length; u++) {
                VRR[u];
                if (VRR[u] == el_2.goods_id) {
                  VRR.splice(u,1)
                }
              }
            }
            console.log(e.checked)
          }
        }
      }
      
      that.BRR = VRR
      console.log(that.BRR)
    },
    Sure_check() {
      var that = this
      axios({
          method: 'post',
          url: '/admin/company/business/saveGoods', 
          data: {
            id: that.Chek_id,
            row:{
              goods_ids:that.BRR
            },
          },
        }).then(function (res) {
            if (res.data.code == 0) {
              that.$message.warning(res.data.msg);
            } else{
                that.show_Fw = false
             }
        })
    },
    //列表
    liebiao(){
      var that = this
      axios.get('/admin/ajax/siteList',{   // 站点列表    
      }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
          that.SiteList = res.data.data
        }
      })
      axios.get('/admin/company/business/statisticsColumns',{
        }).then(function (res) {
          if (res.data.code == 0) {
            alert('请求失败1');  
          } else{
            that.statisticsColumns = res.data.data
          }
      })  
      axios.get('/admin/ajax/staffList',{   // 全部员工列表
        }).then(function (res) {
        if (res.data.code == 0) {
          alert('请求失败');  
        } else{ 
            that.StaFFList = res.data.data
        }
      }) 
    },
     UP_Success(e) {  //图片上传成功
      var that = this
      that.Row.avatar = e.data.url
      that.aTY = 0
      console.log(that.Row_Eurl)
    },
    UP_Success2(e) {  //图片上传成功
      var that = this
      that.Row.wxqrcode = e.data.url
      that.aTY = 0
    },
    HAndemove(file, fileList) {
      var that = this
      that.Row_Eurl = []
        for (let i = 0; i < fileList.length; i++) {
          const element = fileList[i];
          that.Row_Eurl.push(element.response.data.url)
        }
    },
    HAndemove2(file, fileList) {
      var that = this
      that.Row_Eurl2 = []
      for (let i = 0; i < fileList.length; i++) {
        const element = fileList[i];
        that.Row_Eurl2.push(element.response.data.url)   
      }
    },
    Close(){  //关闭按钮
      var that = this
      that.ruleForm2 = false;
      that.Row = {}
    },
    handhange(e) {          // 勾选
      var that = this
      var arr = []
      var acc = []
      if (e.length == 0) {
        that.multipleSelection_id = null
       } else {
        for (let i = 0; i < e.length; i++) {
          const element = e[i];
          arr.push(element.id)
          acc.push(element)
        }
          that.multipleSelection_id = arr
      }
      that.multipleSelection = acc
    },
  },
};
</script>

<style scoped> 
#container {
  width: 100%;
  height: 400px;
  background: #d9d9d9;
}
.ServiceName{
  font-size: 15px;
  font-weight: 700;
  margin: 10px 0;
}
.checkbox{
  width: 33.3%;
  margin-bottom: 8px !important;
}
.el-checkbox{
  margin: 0;
}
</style>
